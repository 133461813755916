.toast-container {
  .toast-close-button {
    font-size: 18px;
    margin-right: 4px;
  }

  .ngx-toastr {
    align-items: center;
    background-image: none !important;
    border-radius: $border-radius;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.35);
    display: flex;
    padding: 15px;

    .toast-close-button {
      position: absolute;
      right: 5px;
      top: 0;
    }

    &:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    }

    .icon i::before {
      float: left;
      font-style: normal;
      font-family: $icomoon-font-family;
      font-size: 25px;
      line-height: 20px;
      padding-right: 15px;
    }

    .toast-message {
      p {
        margin-bottom: 0.5rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &.toast-danger,
    &.toast-error {
      @include themify($themes) {
        background-color: themed("danger");
      }

      &,
      &:before,
      & .toast-close-button {
        @include themify($themes) {
          color: themed("textDangerColor") !important;
        }
      }

      .icon i::before {
        content: map_get($icons, "error");
      }
    }

    &.toast-warning {
      @include themify($themes) {
        background-color: themed("warning");
      }

      &,
      &:before,
      & .toast-close-button {
        @include themify($themes) {
          color: themed("textWarningColor") !important;
        }
      }

      .icon i::before {
        content: map_get($icons, "exclamation-triangle");
      }
    }

    &.toast-info {
      @include themify($themes) {
        background-color: themed("info");
      }

      &,
      &:before,
      & .toast-close-button {
        @include themify($themes) {
          color: themed("textInfoColor") !important;
        }
      }

      .icon i:before {
        content: map_get($icons, "info-circle");
      }
    }

    &.toast-success {
      @include themify($themes) {
        background-color: themed("success");
      }

      &,
      &:before,
      & .toast-close-button {
        @include themify($themes) {
          color: themed("textSuccessColor") !important;
        }
      }

      .icon i:before {
        content: map_get($icons, "check");
      }
    }
  }
}
